$(function() {
  var $primaryMenu, $triggers, activeTagClass, activeTriggerClass, anchoredClass, primaryMenuAnchored;
  activeTagClass = 'photo-tag__tag--active';
  activeTriggerClass = 'photo-tag__trigger--active';
  $triggers = $('.photo-tag__trigger').each(function(index, item) {
    var $both, $tag, $trigger;
    $trigger = $(item);
    $tag = $('.photo-tag__tag--' + $trigger.data('tag'));
    $both = $trigger.add($tag);
    $both.on('mouseover focus', function() {
      $trigger.addClass(activeTriggerClass);
      return $tag.addClass(activeTagClass);
    });
    return $both.on('mouseout blur', function() {
      $trigger.removeClass(activeTriggerClass);
      return $tag.removeClass(activeTagClass);
    });
  });
  $primaryMenu = $('.primary-menu');
  primaryMenuAnchored = true;
  anchoredClass = 'primary-menu--anchored';
  return $(document).on('scroll', function() {
    var atTop;
    atTop = $(window).scrollTop() < 10;
    if (atTop && !primaryMenuAnchored) {
      return primaryMenuAnchored = !!$primaryMenu.addClass(anchoredClass);
    } else if (!atTop && primaryMenuAnchored) {
      return primaryMenuAnchored = !$primaryMenu.removeClass(anchoredClass);
    }
  });
});
